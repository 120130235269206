.pin-icon,
.radio-pin-icon, 
.qr-square-icon, 
.conductor-icon {
    width: auto;
    height: 40px;
    margin: 0 0 0 10px;
}

.icon-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #7c7777;
    padding: var(--padding-8xs) 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1001;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px;
    flex: 1;
    min-width: 80px;
    max-width: 200px;
}

.enter-a-post {
    font-size: 14px;
    margin-right: 10px;
    white-space: nowrap;
    color: white;
}

.drag-instruction {
    font-size: 12px;
    text-align: center;
    margin-top: 5px;
    flex-basis: 100%;
    color: white;
}

@media (max-width: 768px) {
    .icon-bar {
        justify-content: center;
    }

    .label {
        flex-basis: 25%;
    }

    .drag-instruction {
        order: -1;
        margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
    .label {
        flex-basis: 50%;
    }
}

.map-view {
    position: relative;
    background-color: var(--neutral-white);
    width: 100%;
    height: 100vh;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#interactiveMap {
    height: calc(100% - 80px) !important;
    width: 100% !important;
    margin-bottom: 60px; /* Add space for the icon bar */
}

.undo-button {
    position: absolute;
    bottom: 80px; /* Adjusted to be above the icon bar */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #333333;
    border: 2px solid #cccccc;
    border-radius: 25px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 400px;
}

.undo-button:hover {
    background-color: #f0f0f0;
    border-color: #999999;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.undo-button:active {
    background-color: #e0e0e0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transform: translateX(-50%) translateY(1px);
}

.undo-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: #f0f0f0;
    border-color: #cccccc;
    box-shadow: none;
}

@media (max-width: 768px) {
    .undo-button {
        bottom: 80px; /* Adjusted to be above the icon bar */
        padding: 8px 16px;
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .undo-button {
        bottom: 80px; /* Adjusted to be above the icon bar */
        padding: 6px 12px;
        font-size: 11px;
    }
}
