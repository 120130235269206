.password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    max-width: 400px;
    width: 95%;
    margin: 2rem auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.password-container label {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 0.5rem;
    width: 100%;
    text-align: left;
}

.password-container button {
    min-width: 120px;
    width: auto;
    height: 40px;
    background-color: #1976d2;
    color: white;
    border: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-transition: background-color 0.2s;
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    transition: background-color 0.2s;
    padding: 0 16px;
}

.password-container button:hover:not(:disabled) {
    background-color: #1565c0;
}

.password-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 0.7;
}

.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    outline: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1300;
}

/* Loading state styles */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    z-index: 1000;
}

/* Error message styles */
.error-message {
    color: #d32f2f;
    margin: 0.5rem 0;
    font-size: 0.875rem;
    width: 100%;
    word-wrap: break-word;
    text-align: left;
}

/* Success message styles */
.success-message {
    color: #2e7d32;
    margin: 0.5rem 0;
    font-size: 0.875rem;
    width: 100%;
    word-wrap: break-word;
    text-align: left;
}

/* Form field styles */
.password-container .MuiTextField-root {
    width: 100%;
    margin: 0.5rem 0;
}

/* Snackbar custom styles */
.MuiSnackbar-root {
    z-index: 1400;
}

.MuiAlert-root {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.signup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    z-index: 1000;
}

.signup-overlay > * {
    background-color: white;
    padding: 20px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
}

/* Focus styles for accessibility */
.password-container button:focus,
.password-container input:focus {
    outline: 2px solid #1976d2;
    outline-offset: 2px;
}

/* Mobile-specific adjustments */
@media screen and (max-width: 600px) {
    .password-container {
        padding: 1.5rem;
        margin: 1rem auto;
    }
    
    .modal-box {
        padding: 1.5rem;
        width: 95%;
    }
}

/* For older Safari support */
@supports (-webkit-overflow-scrolling: touch) {
    .modal-box {
        -webkit-overflow-scrolling: touch;
    }
    
    /* Fix for iOS input styling */
    input[type="password"],
    input[type="text"] {
        -webkit-appearance: none;
        appearance: none;
        border-radius: 0;
    }
}

/* For IE11 support */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .password-container,
    .loading-overlay,
    .signup-overlay {
        display: block;
    }
    
    .password-container {
        text-align: center;
    }
    
    .password-container button {
        display: inline-block;
        text-align: center;
    }
}

/* Add accessibility classes */
.sr-only,
.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

/* Container for the password entry */